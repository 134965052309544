<template>
  <v-layout>
    <app-loader
      title="Загрузка данных документов"
      :loading="!pageLoaded"
    />
    <v-layout
      v-show="pageLoaded"
      column
      class="no-print"
    >
      <v-flex>
        <v-card
          tile
          class="mt-0 mb-1 ml-0 pa-2 elevation-1"
        >
          <v-layout align-center>
            <v-tooltip bottom>
              <template v-slot:activator="{ on}">
                <v-btn
                  slot="activator"
                  small
                  icon
                  class="ma-0"
                  @click="$router.go(-1)"
                  v-on="on"
                >
                  <v-icon color="grey darken-1">
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Назад</span>
            </v-tooltip>
            <h2 class="px-2 title">
              Документы <span
                v-if="attachments.length || attachmentsForExport.length"
                class="blue--text text--darken-4"
              >{{
                attachments.length + attachmentsForExport.length
              }}</span>
            </h2>
          </v-layout>
          <v-layout>
            <span class="primary--text">Поставка №
              <span class="primary--text text-decoration-underline"> {{ number }} </span> </span>
          </v-layout>
        </v-card>
      </v-flex>
      <v-card
        :style="{ height: blockHeight + 'px', 'overflow-y': 'auto' }"
        tile
        class="mt-0 mb-1 ml-0 pa-2 elevation-1"
      >
        <Attachments
          v-model="attachmentsForExport"
          :block-height="blockHeight / 2"
          :resource="`shipping/${id}`"
          :disabled-sender="true"
          :disabled-upload="true"
          :disabled-download="false"
          :type="attachmentsType.EXPORT"
          title="Экспортные документы"
          :client-id="id"
          :style="{ height: cardHeight + 'px', 'overflow-y': 'auto' }"
        />
        <v-divider class="mt-2" />
        <Attachments
          v-model="attachments"
          :block-height="blockHeight / 2"
          :resource="`shipping/${id}`"
          :disabled-sender="false"
          :disabled-upload="false"
          :disabled-download="false"
          title="Другие документы"
          :client-id="id"
          :draft-id="draftId"
          :style="{ height: cardHeight + 'px', 'overflow-y': 'auto' }"
        />
      </v-card>
    </v-layout>
  </v-layout>
</template>

<script>
import * as shippingsApi from "../../../backend/shippingsApi.js";
import Attachments from "../../../components/attachments/Attachments.vue";
import {
  attachmentsType,
} from "../../../enums/attachments-types.js";

export default {
  components: {
    Attachments,
  },
  props: ["id", "draftId"],
  data() {
    return {
      loading: false,
      dataRestored: false,
      number: "",
      attachmentsForExport: [],
      attachments: [],
    };
  },
  computed: {
    blockHeight() {
      return window.innerHeight - 230;
    },
    cardHeight() {
      return (window.innerHeight - 260) / 2;
    },
    pageLoaded() {
      return !!this.dataRestored;
    },
    attachmentsType() {
      return attachmentsType;
    },
  },
  created() {
    this.getAttachments();
  },
  methods: {
    async getAttachments() {
      try {
        this.loading = true;
        const res = await shippingsApi.getShipping(this.id);
        this.attachmentsForExport = res.data.attachmentsForExport;
        this.attachments= res.data.attachments;
        this.number = res.data.number;
      } catch (err) {
        this.showError("Ошибка при получении документов");
      } finally {
        this.loading = false;
        this.dataRestored = true;
      }
    },
  },
};
</script>
